<template>
  <div class="mainpage">
    <div class="head">
      <div class="head-title">实习报告查看({{ type }})</div>
      <div class="rightbt">
        <div class="bt retutnbt" @click="toback">返回</div>
        <div v-if="type == '退回'" class="bt" @click="editorbt">编辑</div>
      </div>
    </div>
    <div class="line"></div>
    <div class="maincontain">
      <div class="h-contain">
        <div class="h-title">{{ plan_info.name }}</div>
        <div class="l-item">
          <div class="title title1">
            <span>{{ plan_info.user_name }}</span>
            <span>{{ plan_info.student_sn }}</span>
          </div>
          <div class="title">实习形式：{{ plan_info.type | typeformat }}</div>
        </div>
        <div class="l-item">
          <div class="title title1">
            {{ plan_info.college_name }}/{{ plan_info.class_name }}/{{
              plan_info.major_name
            }}
          </div>
          <div class="title">
            实习时间： {{ plan_info.start_date | dataformat }} ~
            {{ plan_info.end_date | dataformat }}
          </div>
        </div>
        <div class="l-item">
          <div class="title title1">实习计划：{{ plan_info.plan_name }}</div>
          <div class="title">
            要求提交时间：
            {{ plan_info.report_commit_start_date | dataformat }} ~
            {{ plan_info.report_commit_end_date | dataformat }}
          </div>
        </div>
      </div>
      <div class="b-contain">
        <div class="item-contain">
          <div class="title">实习纪要：</div>
          <div class="text">{{ report_info.intro }}</div>
        </div>

        <div class="item-contain">
          <div class="title">实习报告：</div>
          <div class="text" v-html="report_info.content"></div>
        </div>

        <div class="item-contain">
          <div class="title">附件：</div>
          <template v-if="Array.isArray(report_info.file)">
            <div
              class="text file-p"
              v-for="(item, index) in report_info.file"
              :key="index"
            >
              <i>{{ item.name }}</i>
              <i class="el-icon-view icon-size" @click="handelView(item)"></i>
              <i class="el-icon-download icon-size" @click="download(item)"></i>
            </div>
          </template>
          <template v-else>
            <div class="text file-p">{{ report_info.file }}</div>
          </template>
        </div>
      </div>

      <div class="f-contain">
        <div class="f-title">评分： {{ report_info.score }}</div>
        <div class="f-title">评语：{{ report_info.reply_content }}</div>
      </div>
    </div>

    <el-dialog
      title="文件预览"
      :visible.sync="dialogVisible"
      :width="widthdialog"
      append-to-body
    >
      <!-- <FileView v-if="flag" :url="url" :type="file_type" /> -->
      <newFileView
        v-if="flag"
        :view-item="itemFile"
        @closeView="closeFileView"
      />
    </el-dialog>
  </div>
</template>

<script>
import { getpracticereportinfo } from "@/api/practicereport";
// import FileView from "@/components/file-view.vue"
import newFileView from "@/components/newFileView.vue";
import dayjs from "dayjs";

const typemap = ["自主报名", "集中安排"];

export default {
  name: "mainpage",
  components: {
    newFileView,
  },
  filters: {
    dataformat(value) {
      if (!value) return "";
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
    },
    typeformat(val) {
      return typemap[val];
    },
  },

  data() {
    return {
      plan_info: {},
      report_info: {},
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/查看.png"),
      icon2: require("@/assets/下载.png"),
      dialogVisible: false,
      flag: false,
      itemFile: {},
      widthdialog: "",
    };
  },
  computed: {
    type() {
      return this.$route.query.type;
    },
    practice_plan_id() {
      return this.$route.query.practice_plan_id;
    },
    practice_report_id() {
      return this.$route.query.practice_report_id;
    },
    student_id() {
      return this.$route.query.student_id;
    },
  },
  created() {
    this.getpracticereportinfo();
    if (this.isMobile()) {
      this.widthdialog = "100%";
    } else {
      this.widthdialog = "70%";
    }
  },
  methods: {
    // 判断手机端还是pc端
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    toback() {
      this.$router.push({
        path: "/home/personalcenter/mainpage",
        query: {
          id: 6,
          componentId: "Myinternship",
        },
      });
    },
    editorbt() {
      this.$router.replace({
        path: "/home/personalcenter/submitreport",
        query: {
          status: "编辑",
          practice_plan_id: this.practice_plan_id,
          practice_report_id: this.practice_report_id,
          student_id: this.student_id,
        },
      });
    },
    todetail() {
      this.$router.push({
        path: "/home/personalcenter/interactiveqadetails",
      });
    },
    getpracticereportinfo() {
      const params = {
        id: this.practice_report_id,
        practice_plan_id: this.practice_plan_id,
        student_id: this.student_id,
      };
      getpracticereportinfo(params)
        .then((response) => {
          if (response.code === 0) {
            this.plan_info = response.data.plan_info;
            this.report_info = response.data.report_info;
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    checkbt() {
      this.$refs.customdialog.dialogopenbt();
    },
    // 下载
    download(item) {
      var ele = document.createElement("a"); // 创建下载链接
      ele.download = item.name; //设置下载的名称
      ele.style.display = "none"; // 隐藏的可下载链接
      ele.href = item.url;
      // 绑定点击时间
      document.body.appendChild(ele);
      ele.click();
      // 然后移除
      document.body.removeChild(ele);
    },
    handelView(item) {
      this.itemFile = {
        url: item.url,
        ext: item.ext,
      };
      this.dialogVisible = true;
      this.flag = false;
      this.$nextTick(() => {
        this.flag = true;
      });
    },
    closeFileView(data) {
      this.dialogVisible = data;
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 640px) {
  .mainpage {
    .head {
      background: #fff;
      margin-top: 66px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border-bottom: 2px solid #cccccc;
      .head-title {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #3d84ff;
        user-select: none;
        white-space: nowrap;
      }
      .rightbt {
        display: flex;
        justify-content: center;
        align-items: center;
        .bt {
          margin-right: 10px;
          width: 64px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          background: #3d84ff;
          border: 1px solid #3d84ff;
          border-radius: 4px;
          color: white;
          user-select: none;
          cursor: pointer;
        }
      }
    }
    .maincontain {
      border-radius: 4px;
      .h-contain {
        min-height: 170px;
        background: #fff;
        box-sizing: border-box;
        padding: 10px;
        padding-left: 20px;
        margin-bottom: 20px;
        .h-title {
          font-size: 25px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
        }
        .l-item {
          margin-top: 20px;
          // display: flex;
          // justify-content: flex-start;
          // align-items: center;

          .title {
            font-size: 20px;
            font-weight: 400;
            color: #666666;
            line-height: 40px;
          }
          .title1 {
            flex-basis: 600px;
          }
        }
      }
      .b-contain {
        min-height: 200px;
        background: #fff;
        box-sizing: border-box;
        padding: 10px;
        padding-left: 20px;
        margin-bottom: 20px;
        .item-contain {
          margin-bottom: 40px;
          .title {
            font-size: 20px;
            font-weight: 500;
            color: #000;
          }
          .preface {
            margin-top: 10px;
            font-size: 18px;
            font-weight: 400;
            color: #666666;
          }
          .text {
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            line-height: 26px;
            margin-top: 10px;
          }
          .preface-item {
            .preface2 {
              margin-top: 5px;
              font-size: 16px;
              font-weight: 400;
              color: #666666;
            }
            .text {
              font-size: 16px;
              font-weight: 400;
              color: #666666;
              line-height: 26px;
              margin-top: 10px;
            }
          }
        }
        .downbt {
          margin-top: 30px;
          margin-left: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .elimage {
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }

      .f-contain {
        margin-bottom: 20px;
        padding: 20px;
        padding-left: 20px;
        min-height: 100px;
        background: #fff;
        box-sizing: border-box;

        .f-title {
          margin-bottom: 20px;
          font-size: 18px;
          font-weight: 400;
          color: #222222;
        }
      }
    }

    .file-p {
      i {
        margin-right: 10px;
        font-style: normal;
      }
      .icon-size {
        color: #3d84ff;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .mainpage {
    .head {
      background: #fff;
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border-bottom: 2px solid #cccccc;
      .head-title {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #3d84ff;
        user-select: none;
        white-space: nowrap;
      }
      .rightbt {
        display: flex;
        justify-content: center;
        align-items: center;
        .bt {
          margin-right: 20px;
          width: 64px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          background: #3d84ff;
          border: 1px solid #3d84ff;
          border-radius: 4px;
          color: white;
          user-select: none;
          cursor: pointer;
        }
      }
    }
    .maincontain {
      border-radius: 4px;
      .h-contain {
        min-height: 170px;
        background: #fff;
        box-sizing: border-box;
        padding: 20px;
        padding-left: 20px;
        margin-bottom: 20px;
        .h-title {
          font-size: 25px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
        }
        .l-item {
          margin-top: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .title {
            font-size: 20px;
            font-weight: 400;
            color: #666666;
          }
          .title1 {
            flex-basis: 600px;
          }
        }
      }
      .b-contain {
        min-height: 200px;
        background: #fff;
        box-sizing: border-box;
        padding: 20px;
        padding-left: 20px;
        margin-bottom: 20px;
        .item-contain {
          margin-bottom: 40px;
          .title {
            font-size: 20px;
            font-weight: 500;
            color: #000;
          }
          .preface {
            margin-top: 10px;
            font-size: 18px;
            font-weight: 400;
            color: #666666;
          }
          .text {
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            line-height: 26px;
            margin-top: 10px;
          }
          .preface-item {
            .preface2 {
              margin-top: 5px;
              font-size: 16px;
              font-weight: 400;
              color: #666666;
            }
            .text {
              font-size: 16px;
              font-weight: 400;
              color: #666666;
              line-height: 26px;
              margin-top: 10px;
            }
          }
        }
        .downbt {
          margin-top: 30px;
          margin-left: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .elimage {
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }

      .f-contain {
        margin-bottom: 20px;
        padding: 20px;
        padding-left: 20px;
        min-height: 100px;
        background: #fff;
        box-sizing: border-box;

        .f-title {
          margin-bottom: 20px;
          font-size: 18px;
          font-weight: 400;
          color: #222222;
        }
      }
    }

    .file-p {
      i {
        margin-right: 30px;
        font-style: normal;
      }
      .icon-size {
        color: blue;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
}
</style>
